import { NavItem } from './nav-item/nav-item';

export const adminItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'aperture',
    route: '/dashboards/dashboard1',
  },
 
  {
    navCap: 'Quick Actions',
  },

  
  
  {
    displayName: 'Trip Operations',
    iconName: 'receipt-2',
    route: 'apps/operations',
    children: [
      {
        displayName: 'Add Task',
        iconName: 'point',
        route: 'apps/operations/add-task',
      },
      {
        displayName: 'Tasks',
        iconName: 'point',
        route: 'apps/operations/tasks',
      },
      {
        displayName: 'Schedule Trip',
        iconName: 'point',
        route: 'apps/operations/create-trip',
      },
      {
        displayName: 'Trips',
        iconName: 'point',
        route: 'apps/operations/trips',
      },
      {
        displayName: 'Fleet',
        iconName: 'point',
        route: 'apps/operations/fleet',
      },
      {
        displayName: 'Trip Issues',
        iconName: 'point',
        route: 'apps/operations/issues',
      },
      {
        displayName: 'Routes',
        iconName: 'point',
        route: 'apps/operations/routes',
      }
    ],
  },

  // {
  //   displayName: 'Transporters',
  //   iconName: 'truck-delivery',
  //   route: 'apps/transporters',
  //   children: [
  //     {
  //       displayName: 'Add Transporter',
  //       iconName: 'point',
  //       route: 'apps/transporters/add',
  //     },
  //     {
  //       displayName: 'Transpoters',
  //       iconName: 'point',
  //       route: 'apps/transporters/list',
  //     }
  //   ],
  // },

  {
    displayName: 'Prime Mover',
    iconName: 'tir',
    route: 'apps/vehicles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/vehicles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/vehicles/list',
      }
    ],
  },
  {
    displayName: 'Trailers',
    iconName: 'caravan',
    route: 'apps/trailers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/trailers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/trailers/list',
      }
    ],
  },
  {
    displayName: 'Expense',
    iconName: 'receipt-2',
    route: 'apps/expenses',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/expenses/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/expenses/list',
      }
    ],
  },
  
  
  {
    displayName: 'Products',
    iconName: 'stack-push',
    route: 'apps/products',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/products/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/products/list',
      }
    ],
  },
  

  {
    displayName: 'Workshop',
    iconName: 'car-crash',
    route: 'apps/workshops',
    children: [
      {
        displayName: 'Jobcard',
        iconName: 'point',
        route: 'apps/workshops/jobcard',
      },
      {
        displayName: 'Jobcards',
        iconName: 'point',
        route: 'apps/workshops/jobacards',
      }
    ],
  },
  
  {
    displayName: 'Customers',
    iconName: 'users-group',
    route: 'apps/customers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/customers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/customers/list',
      }
    ],
  },
  {
    displayName: 'Suppliers',
    iconName: 'users',
    route: 'apps/suppliers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/suppliers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/suppliers/list',
      }
    ],
  },

  {
    displayName: 'Documents',
    iconName: 'books',
    route: 'apps/documents',
    children: [
    
      {
        displayName: 'Documents List',
        iconName: 'point',
        route: 'apps/documents/list',
      }
    ],
  },


 
  {
    displayName: 'Fuel Stations',
    iconName: 'gas-station',
    route: 'apps/stations',
    children: [
      {
        displayName: 'Add Station',
        iconName: 'point',
        route: 'apps/stations/add',
      },
      {
        displayName: 'Stations',
        iconName: 'point',
        route: 'apps/stations/list',
      }
    ],
  },

  {
    displayName: 'Orders',
    iconName: 'shopping-cart-copy',
    route: 'apps/orders',
    children: [
      {
        displayName: 'Fuel Order',
        iconName: 'point',
        route: 'apps/orders/fuel-orders',
      },
      {
        displayName: 'Purchase Order',
        iconName: 'point',
        route: 'apps/orders/purchase-order',
      },
      {
        displayName: 'Purchase Orders',
        iconName: 'point',
        route: 'apps/orders/purchase-orders',
      }
    ],
  },
  {
    displayName: 'Reports',
    iconName: 'chart-pie',
    route: 'apps/reports',
    children: [
      {
        displayName: 'Fuel Reports',
        iconName: 'point',
        route: 'apps/reports/fuel',
      }
    ],
  },

  {
    displayName: 'Drivers',
    iconName: 'user-plus',
    route: 'apps/drivers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/drivers/add',
      },
      {
        displayName: 'Drivers',
        iconName: 'point',
        route: 'apps/drivers/list',
      }
    ],
  },


  {
    displayName: 'Add Employee',
    iconName: 'user-plus',
    route: 'apps/add-employee',
  },

  {
    displayName: 'Employees',
    iconName: 'users',
    route: 'apps/employee',
  },

  {
    navCap: 'Settings',
  },


  
  {
    displayName: 'Company',
    iconName: 'home-2',
    route: 'apps/company',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/company/add-company',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/company/list',
      }
    ],
  },


  {
    displayName: 'Branchs',
    iconName: 'brand-stackshare',
    route: 'apps/branch',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/branch/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/branch/branch-list',
      }
    ],
  },
  {
    displayName: 'Categories',
    iconName: 'layout',
    route: 'widgets',
    children: [
      {
        displayName: 'List',
        iconName: 'point',
        route: 'categories/list',
      },
     
    ],
  },
 
  {
    displayName: 'Roles',
    iconName: 'lock-access',
    route: 'apps/roles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/roles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/roles/list',
      }
    ],
  },
 


  {
    displayName: 'Account Setting',
    iconName: 'user-circle',
    route: 'apps/users/account',
  },

];
